import { BaseBlog } from "@templates";
import { graphql } from "gatsby";
import React from "react";

const Revue = ({ pageContext: { page }, ...props }) => {
    return (
        <BaseBlog
            className={`template-revue ${props.className || ""}`}
            breadcrumb={{current: {title: props.data.wpPage.title, id: page.id, parent: props.data.wpPage.databaseId}}}
            title={page.title.rendered}
            description={page.content.rendered}
            posts={props.data.allWpPressreview.nodes}
            pagination={{
                slug: page.slug,
                context: props.pageResources?.json?.pageContext,
            }}
            page={page}
        ></BaseBlog>
    );
};

export default Revue;

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!) {
        allWpPressreview(
            sort: { fields: date, order: DESC }
            skip: $skip
            limit: $limit
        ) {
            nodes {
                date
                link
                title
                excerpt
                featuredImage {
                    node {
                      localFile {
                        base
                      }
                      altText
                    }
                  }
                acf{
                    filePressreview {
                        localFile {
                            url
                        }
                    }
                }
            }
            
        }

        wpPage(template: {templateName: {eq: "Modèle Blog"}}) {
            id
            title
            databaseId
          }
    }
`;
